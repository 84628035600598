<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="success--text">Employee</span>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="mb-2 px-4"
          @keypress.enter="searchTable=search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiDeleteOutline, mdiPencilOutline, mdiPlusOutline } from '@mdi/js'
import getData from '@/composables/getData'

export default {
  setup() {
    const search = ref('')
    const headers = ref([
      { text: 'Employee Number', align: 'start', value: 'employee_number' },
      { text: 'Employee Name', value: 'employee_name' },
      { text: 'Position', value: 'position' },
      { text: 'Department / Outlet', value: 'location' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const iconList = {
      add: mdiPlusOutline,
      edit: mdiPencilOutline,
      delete: mdiDeleteOutline,
    }

    const listUri = `${process.env.VUE_APP_URI}/api/payroll/employees/masterlist/active`

    const fetchEmployee = () => {
      getData(listUri, items, totalItems, loading, options, search)
    }

    watch(options, () => {
      fetchEmployee()
    })

    watch(searchTable, () => {
      fetchEmployee()
    })

    return {
      search,
      headers,
      items,
      searchTable,
      iconList,
      options,
      totalItems,
      loading,
    }
  },
}
</script>

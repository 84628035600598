<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <dashboard-welcome></dashboard-welcome>
      <dashboard-birthday-celebrant></dashboard-birthday-celebrant>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <dashboard-employee></dashboard-employee>
      <v-row class="match-height mt-4">
        <v-col
          cols="12"
          sm="3"
        >
          <statistics-card-vertical
            :change="totalOvertime.change"
            :color="totalOvertime.color"
            :icon="totalOvertime.icon"
            :statistics="totalOvertime.statistics"
            :stat-title="totalOvertime.statTitle"
            :subtitle="totalOvertime.subtitle"
            :loading="overtimeLoading"
          ></statistics-card-vertical>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <statistics-card-vertical
            :change="totalUndertime.change"
            :color="totalUndertime.color"
            :icon="totalUndertime.icon"
            :statistics="totalUndertime.statistics"
            :stat-title="totalUndertime.statTitle"
            :subtitle="totalUndertime.subtitle"
            :loading="undertimeLoading"
          ></statistics-card-vertical>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <statistics-card-vertical
            :change="totalLate.change"
            :color="totalLate.color"
            :icon="totalLate.icon"
            :statistics="totalLate.statistics"
            :stat-title="totalLate.statTitle"
            :subtitle="totalLate.subtitle"
            :loading="lateLoading"
          ></statistics-card-vertical>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <statistics-card-vertical
            :change="totalNightDifferential.change"
            :color="totalNightDifferential.color"
            :icon="totalNightDifferential.icon"
            :statistics="totalNightDifferential.statistics"
            :stat-title="totalNightDifferential.statTitle"
            :subtitle="totalNightDifferential.subtitle"
            :loading="nightDifferentialLoading"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import {
  mdiArrowUpBoldOutline,
  mdiArrowDownBoldOutline,
  mdiAccountClockOutline,
  mdiWeatherNight,
} from '@mdi/js'
import get from '@/composables/get'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
import DashboardWelcome from './DashboardWelcome.vue'
import DashboardBirthdayCelebrant from './DashboardBirthdayCelebrant.vue'
import DashboardEmployee from './DashboardEmployee.vue'

export default {
  metaInfo: {
    title: 'Dashboard',
  },
  components: {
    StatisticsCardVertical,
    DashboardWelcome,
    DashboardBirthdayCelebrant,
    DashboardEmployee,
  },
  setup() {
    const overtimeLoading = ref(false)
    const overtime = ref({})
    const totalOvertime = ref({})
    const undertimeLoading = ref(false)
    const undertime = ref({})
    const totalUndertime = ref({})
    const lateLoading = ref(false)
    const late = ref({})
    const totalLate = ref({})
    const nightDifferentialLoading = ref(false)
    const nightDifferential = ref({})
    const totalNightDifferential = ref({})

    const getStatistics = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/dashboard/overtime-statistics`, overtime, overtimeLoading)
      await get(`${process.env.VUE_APP_URI}/api/payroll/dashboard/undertime-statistics`, undertime, undertimeLoading)
      await get(`${process.env.VUE_APP_URI}/api/payroll/dashboard/late-statistics`, late, lateLoading)
      await get(`${process.env.VUE_APP_URI}/api/payroll/dashboard/night-differential-statistics`, nightDifferential, nightDifferentialLoading)

      const OT = overtime.value.current > 0 ? ((overtime.value.current - overtime.value.previous) / overtime.value.current) * 100 : 0
      const OTSign = OT > 0 ? '+' : ''
      totalOvertime.value = {
        statTitle: 'Overtime',
        icon: mdiArrowUpBoldOutline,
        color: 'success',
        subtitle: 'Compared to last cutoff',
        statistics: Intl.NumberFormat().format(overtime.value.current.toFixed(2)),
        change: `${OTSign}${(OT).toFixed(2).toString()}%`,
      }

      const UT = undertime.value.current > 0 ? ((undertime.value.current - undertime.value.previous) / undertime.value.current) * 100 : 0
      const UTSign = UT > 0 ? '+' : ''
      totalUndertime.value = {
        statTitle: 'Undertime',
        icon: mdiArrowDownBoldOutline,
        color: 'secondary',
        subtitle: 'Compared to last cutoff',
        statistics: Intl.NumberFormat().format(undertime.value.current.toFixed(2)),
        change: `${UTSign}${(UT).toFixed(2).toString()}%`,
      }

      const lt = late.value.current > 0 ? ((late.value.current - late.value.previous) / late.value.current) * 100 : 0
      const ltSign = lt > 0 ? '+' : ''
      totalLate.value = {
        statTitle: 'Late',
        icon: mdiAccountClockOutline,
        color: 'primary',
        subtitle: 'Compared to last cutoff',
        statistics: Intl.NumberFormat().format(late.value.current.toFixed(2)),
        change: `${ltSign}${(lt).toFixed(2).toString()}%`,
      }

      const ND = nightDifferential.value.current > 0 ? ((nightDifferential.value.current - nightDifferential.value.previous) / nightDifferential.value.current) * 100 : 0
      const NDSign = ND > 0 ? '+' : ''
      totalNightDifferential.value = {
        statTitle: 'Night Differential',
        icon: mdiWeatherNight,
        color: 'warning',
        subtitle: 'Compared to last cutoff',
        statistics: Intl.NumberFormat().format(nightDifferential.value.current.toFixed(2)),
        change: `${NDSign}${(ND).toFixed(2).toString()}%`,
      }
    }

    getStatistics()

    return {
      overtimeLoading,
      overtime,
      undertimeLoading,
      undertime,
      lateLoading,
      late,
      nightDifferentialLoading,
      nightDifferential,
      totalOvertime,
      totalUndertime,
      totalLate,
      totalNightDifferential,
    }
  },
}
</script>

<template>
  <v-card class="mt-6">
    <v-card-title class="align-start">
      <span class="info--text">Birthday Celebrants!</span>
    </v-card-title>

    <v-card-text style="max-height: 510px; overflow:auto">
      <div
        v-for="(data, index) in celebrants"
        :key="index"
        :class="`d-flex align-start ${index > 0 ? 'mt-8':''}`"
      >
        <v-avatar
          rounded
          size="70"
          color="#5e56690a"
          class="me-4"
        >
          <v-img
            height="40"
            :src="data.image !== null ? `${route}/image/${fnx.base64_encode(data.image)}` : defaultImage"
          ></v-img>
        </v-avatar>

        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div>
            <h4 class="font-weight-medium">
              {{ data.name }}
            </h4>
            <p class="text-xs text-no-wrap">{{ data.location }}</p>
            <p class="text-xs text-no-wrap">{{ data.birthdate }}</p>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import get from '@/composables/get'
import fnx from '@/functions/fn'

export default {
  setup() {
    const celebrants = ref([])
    const celebrantLoading = ref(false)
    const defaultImage = require('@/assets/images/avatars/not-available.png')

    const getCelebrants = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/employees/birthday-celebrant`, celebrants, celebrantLoading)
    }

    getCelebrants()

    const month = new Date().toLocaleString('en-us', { month: 'long' })

    return {
      celebrants,
      celebrantLoading,
      month,
      defaultImage,
      route: process.env.VUE_APP_URI,
      fnx,
    }
  },
}
</script>
